import React from 'react';
import './App.css';

function App() {
  return (
    <main className="App">
      {'Apples & Acorns'}
    </main>
  );
}

export default App;
